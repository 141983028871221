@font-face {
    font-family: 'Antartica';
    src: url('../../public/cloud/fonts/Antarctica-Bold.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Antartica';
    src: url('../../public/cloud/fonts/Antarctica-SemiBold.otf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Antartica';
    src: url('../../public/cloud/fonts/Antarctica-Medium.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Antartica';
    src: url('../../public/cloud/fonts/Antarctica-Regular.otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Antartica';
    src: url('../../public/cloud/fonts/Antarctica-Light.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;

}
