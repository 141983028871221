
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./fonts.css');

@layer components {
    .container {
      @apply w-full md:max-w-[1128px]
    }
}


.btn {
    @apply focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none
}

.btn-social {
    @apply focus:ring-4 font-medium rounded-lg text-sm px-3 py-2.5 focus:outline-none h-11
}


.input {
    @apply disabled:bg-gray-200 placeholder:text-gray-600 bg-white h-11 border-gray-500 text-gray-900 text-sm rounded-lg border-1 focus:ring-violet-500 focus:border-violet-500 focus:bg-white focus:shadow-1-purple block w-full p-2.5 outline-0
}

.input-error {
    @apply disabled:bg-gray-200 placeholder:text-red-600 bg-white h-11 border-red-500 text-red-900 text-sm rounded-lg border-1 focus:ring-red-500 focus:border-red-500 focus:bg-white focus:shadow-1-red block w-full p-2.5 outline-0
}

.input-error-description {
    @apply text-red-500 text-xs mt-1
}

.label {
    @apply block mb-2 text-sm font-medium text-picoblack 
}

.card {
    @apply border-b-1 md:border-1 rounded-none md:rounded-2xl bg-white py-6 px-5 md:py-6 md:px-8  border-black/5 mb-6 last:mb-0
}


.heading-1 {
    @apply text-picoblack text-base leading-heading tracking-heading font-medium font-antartica
}
.otp {
    @apply w-full
}

.otp input {
    @apply input mr-2 w-16
}

.tab {
    @apply rounded-lg text-sm text-picoblack/[0.7] font-medium h-11 flex items-center justify-center cursor-pointer
}
.tab.active {
    @apply bg-violet-100 text-violet-500 
}
.tab.active svg > path {
    @apply fill-violet-500
}
.tab.inactive svg > path {
    @apply fill-[#444444]
}
.iti {
    @apply w-full
}

.checkbox {
    @apply opacity-0 absolute h-[18px] w-[18px]
}
.checkbox-paint {
   @apply bg-white border-2 border-gray-500 rounded-[4px] w-[18px] h-[18px] flex flex-shrink-0 justify-center items-center focus-within:border-violet-500 
}
.checkbox:checked + div {
    @apply border-violet-500 bg-violet-500
}
.checkbox:checked + div svg {
    @apply block text-white fill-white
}
.checkbox:checked + div svg > g > g > path {
    @apply  fill-white
}

input[type="radio"] + label .radio-check  {
    @apply bg-white border-2 border-gray-500 hover:border-gray-700 shadow-radio mr-2 w-[18px] h-[18px]
}
  
input[type="radio"]:checked + label .radio-check  {
  @apply bg-violet-500 border-violet-500
}

.card-input {
    @apply bg-white h-[40px] border-gray-500 text-gray-900 text-base rounded-lg border-1 block w-full outline-0 px-3 py-2.5
}

.card-input::placeholder {
    @apply text-base text-gray-600 font-normal
}

.error-input {
    @apply bg-white h-[40px] border-red-500 text-red-900 text-base rounded-lg border-1 block w-full outline-0 px-3 py-2.5
}

.iti--container{
    top: 52px !important;
    bottom: 30px !important;
    left: 0 !important;
    right: 30px !important;
    position: absolute !important;
    height: 151px !important;
}
